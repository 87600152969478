import Cookies from "js-cookie"
import ISpin from "ispin"
import { html } from "lit-html"
import { virtual, useEffect, useState } from "haunted"

export const PaymentSection = virtual((endpoint, roundedBlockMinutes) => {
  const [roleHours, setRoleHours] = useState(NaN)
  const [outsideWorkHours, setOutsideWorkHours] = useState(NaN)
  const [isRemote, setIsRemote] = useState(null)
  const [estimatedPayment, setEstimatedPayment] = useState("")

  const getRoleHoursInput = () => {
    return document.querySelector("#review-form #id_hours")
  }

  const getOutsideWorkHoursInput = () => {
    return document.querySelector("#review-form #id_outside_work_hours")
  }

  const getRoleRemoteCheckboxes = () => {
    return document.querySelectorAll("#review-form [name='remote']")
  }

  const roundMeetingHours = () => {
    const $roleHoursInput = getRoleHoursInput()
    const hours = $roleHoursInput.value
    if (hours === "") {
      return
    }

    const roleHours = parseFloat(hours)
    if (!isNaN(roleHours)) {
      const hourRatio = 60.0 / roundedBlockMinutes
      const roundedHours = Math.ceil(roleHours * hourRatio) / hourRatio
      $roleHoursInput.value = roundedHours
      setRoleHours(roundedHours)
    }
  }

  const bindHoursInputEvent = (el, step, onChange) => {
    new ISpin(el, {
      wrapperClass: "ispin-wrapper",
      buttonsClass: "ispin-button",
      step: step,
      min: "0.000",
      disabled: false,
      wrapOverflow: false,
      parse: Number,
      format: String,
      onChange: onChange,
    })
    el.addEventListener("change", onChange)
  }

  useEffect(() => {
    const $roleHoursInput = getRoleHoursInput()
    setRoleHours(parseFloat($roleHoursInput.value))

    const $outsideWorkHoursInput = getOutsideWorkHoursInput()
    setOutsideWorkHours(parseFloat($outsideWorkHoursInput.value))

    const $roleRemoteCheckboxes = getRoleRemoteCheckboxes()
    setIsRemote($roleRemoteCheckboxes[0].checked)

    if (!$roleHoursInput.readOnly) {
      bindHoursInputEvent($roleHoursInput, roundedBlockMinutes / 60.0, () => {
        roundMeetingHours()
      })
      bindHoursInputEvent($outsideWorkHoursInput, 0.25, () => {
        setOutsideWorkHours($outsideWorkHoursInput.value)
      })
      $roleRemoteCheckboxes.forEach((el) => {
        el.addEventListener("change", () => {
          setIsRemote(el.value === "True")
        })
      })
    }
  }, [])

  const updatePayment = (withOutsideWorkHours = false) => {
    if (
      isNaN(roleHours) ||
      (withOutsideWorkHours && isNaN(outsideWorkHours)) ||
      isRemote === null
    ) {
      if (estimatedPayment !== "") {
        setEstimatedPayment("")
      }
      return
    }

    const roleData = {
      hours: roleHours,
      remote: isRemote,
    }
    if (withOutsideWorkHours) {
      roleData.outside_work_hours = outsideWorkHours
    }

    fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(roleData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!withOutsideWorkHours) {
          const $outsideWorkHoursInput = getOutsideWorkHoursInput()
          $outsideWorkHoursInput.value = res.outside_work_hours
          setOutsideWorkHours(res.outside_work_hours)
        }
        setEstimatedPayment(res.payment_amount)
      })
  }

  useEffect(() => updatePayment(), [roleHours, isRemote])
  useEffect(() => updatePayment(true), [outsideWorkHours])

  return html`
    <input readonly value='$${estimatedPayment}'></input>
  `
})

export default PaymentSection
