import { keyClickWrapper } from "../utils"

export default function Toggle(el) {
  const isExpanded = el.getAttribute("aria-expanded").toString() === "true"
  const controlsEl = document.getElementById(el.getAttribute("aria-controls"))
  controlsEl.classList.toggle("hidden", !isExpanded)
  const onToggle = (e) => {
    e.preventDefault()
    const isExpanded = el.getAttribute("aria-expanded").toString() === "true"
    el.setAttribute("aria-expanded", !isExpanded)

    const controlsEl = document.getElementById(el.getAttribute("aria-controls"))
    controlsEl.classList.toggle("hidden", isExpanded)
  }

  el.addEventListener("click", onToggle)
  el.addEventListener("keydown", (e) => keyClickWrapper(e, onToggle))
}
