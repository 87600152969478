import { DISCOURSE_PATH, NOTIFICATIONS_UNREAD_PATH } from "../constants"

export async function getDiscourseNotifications() {
  return fetch(DISCOURSE_PATH, { credentials: "include" })
    .then((res) => res.json())
    .then((data) => {
      const discourseNotifications =
        "notifications" in data ? data.notifications : []
      return discourseNotifications.filter((n) => !n.read)
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

export async function getUnreadNotificationCount() {
  return fetch(NOTIFICATIONS_UNREAD_PATH, { credentials: "include" })
    .then((res) => res.json())
    .then((data) => data.unread)
    .catch((err) => {
      console.error(err)
      return 0
    })
}

export default async function updateNotifications() {
  const [unreadCount, discourseNotifications] = await Promise.all([
    getUnreadNotificationCount(),
    getDiscourseNotifications(),
  ])
  const notificationIndicator = document.querySelector(
    `[data-component="notification-indicator"]`
  )

  if (notificationIndicator) {
    notificationIndicator.classList.toggle("has-notifications", unreadCount > 0)
  }
  document
    .querySelectorAll(`[data-component="notification-count"]`)
    .forEach((el) => {
      el.innerText = `${unreadCount}`
      if (el.classList.contains("hide-zero")) {
        el.classList.toggle("visually-hidden", unreadCount === 0)
      }
    })

  document
    .querySelectorAll(`[data-component="message-board-notification-indicator"]`)
    .forEach((el) => {
      el.classList.toggle(
        "has-notifications",
        discourseNotifications.length > 0
      )
    })
  document
    .querySelectorAll(`[data-component="message-board-notification-count"]`)
    .forEach((el) => {
      el.innerText = `(${discourseNotifications.length})`
      if (el.classList.contains("hide-zero")) {
        el.classList.toggle(
          "visually-hidden",
          discourseNotifications.length === 0
        )
      }
    })
}
