import { addScript } from "../utils"

const TWITTER_WIDGET_SCRIPT = "https://platform.twitter.com/widgets.js"
const TIMELINE_WIDTH = "750"
const TIMELINE_HEIGHT = "1250"

class TwitterTweetEmbed {
  constructor(el) {
    this.el = el
    this.tweetUrl = this.el.dataset.url
    this.render = this.render.bind(this)

    if (!(window.twttr && window.twttr.ready)) {
      addScript(TWITTER_WIDGET_SCRIPT, this.render)
    } else {
      this.render()
    }
  }

  render() {
    window.twttr.ready().then(({ widgets }) => {
      this.el.innerHTML = ""
      const tweetId = this.tweetUrl.split("/").slice(-1)[0]
      widgets.createTweet(tweetId.toString(), this.el, {})
    })
  }
}

class TwitterTimelineEmbed {
  constructor(el) {
    this.el = el
    this.timelineUrl = this.el.dataset.url
    this.render = this.render.bind(this)

    if (!(window.twttr && window.twttr.ready)) {
      addScript(TWITTER_WIDGET_SCRIPT, this.render)
    } else {
      this.render()
    }
  }

  render() {
    const twitterUser = this.timelineUrl
      .match(/twitter.com\/[^/]+/)[0]
      .split("/")
      .slice(-1)[0]
    const twitterLink = document.createElement("a")
    twitterLink.className = "twitter-timeline"
    twitterLink.dataset.width = TIMELINE_WIDTH
    twitterLink.dataset.height = TIMELINE_HEIGHT
    twitterLink.href = `${this.timelineUrl}?ref_src=twsrc%5Etfw`
    twitterLink.appendChild(
      document.createTextNode(`Curated tweets by ${twitterUser}`)
    )
    this.el.appendChild(twitterLink)
  }
}

function renderTwitterEmbed(el) {
  if (el.dataset.url.search(/\/timelines\//) >= 0) {
    new TwitterTimelineEmbed(el)
  } else {
    new TwitterTweetEmbed(el)
  }
}

export { TwitterTweetEmbed, TwitterTimelineEmbed, renderTwitterEmbed }
