import { html } from "lit-html"
import { virtual, useEffect, useState } from "haunted"
import { debounce } from "../utils"
import { DEBOUNCE_TIME } from "../constants"

const ScrollTopButton = virtual(() => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const onScroll = debounce(
      () => setVisible(window.innerHeight / 2 < window.pageYOffset),
      DEBOUNCE_TIME,
      { passive: true }
    )
    document.addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [setVisible])

  return html`
    <button
      class=${`button text-blue text-red-active ${visible ? "visible" : ""}`}
      type="button"
      aria-hidden=${(!visible).toString()}
      tabindex=${visible ? "0" : "-1"}
      aria-label="Back to Top"
      @click=${() => window.scroll({ top: 0, left: 0, behavior: "smooth" })}
    >
      <i class="fas fa-chevron-up"></i>
    </button>
  `
})

export default ScrollTopButton
