import Cookies from "js-cookie"
import { html, nothing, render } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { virtual, useEffect, useRef, useState } from "haunted"
import { addToastMessage } from "../generic/messages"
import { ref } from "../directives"

const MAX_COUNT_DOCS = 1000

const DOCUMENTERS_HREF = "/programs/users/"

const TYPE_ICON_MAP = {
  agencies: "fas fa-building",
  documents: "fas fa-file",
  meetings: "fas fa-calendar-check",
  documenter: "fas fa-user",
}

const resultOption = ({ url, label, onBlur }) => {
  const nextFocusableSibling = ($node, siblingDirection) => {
    var $nextNode = $node[siblingDirection]
    while ($nextNode !== null && !$nextNode.querySelector("a")) {
      $nextNode = $nextNode[siblingDirection]
    }

    return $nextNode
  }

  const onKeyDown = (e) => {
    if (!["ArrowDown", "ArrowRight", "ArrowUp", "ArrowLeft"].includes(e.code))
      return

    e.preventDefault()
    const $currentOption = e.target.parentElement
    const siblingDirection = ["ArrowDown", "ArrowRight"].includes(e.code)
      ? "nextElementSibling"
      : "previousElementSibling"
    const $nextOption = nextFocusableSibling($currentOption, siblingDirection)
    $nextOption?.querySelector("a")?.focus()
  }

  return html`
    <a
      role="option"
      tabindex="-1"
      @keydown=${onKeyDown}
      @blur=${onBlur}
      href=${url}
    >
      ${label}
    </a>
  `
}

const SearchCountResults = ({ resultsCountList, value, onBlur }) => {
  return html`
    ${repeat(
      resultsCountList,
      (result) => result[0],
      (result) => html`
        <li role="none" class="search-result">
          <span class="icon">
            <i class=${TYPE_ICON_MAP[result[0]]}></i>
          </span>
          ${resultOption({
            url: `/${result[0]}/?search=${value}`,
            label: `${
              result[1] >= MAX_COUNT_DOCS
                ? `${MAX_COUNT_DOCS.toLocaleString("en-US")}+`
                : result[1].toLocaleString("en-US")
            }
            ${result[0]}`,
            onBlur,
          })}
        </li>
      `
    )}
  `
}

const SearchDocumentersResults = ({
  isAuthenticated,
  documentersList,
  numberProfilesLeft,
  programs,
  search,
  onBlur,
}) => {
  const queryParams = `${new URLSearchParams({
    search,
    programs: programs,
    role: "documenter",
  })}`

  const numberProfilesLeftLabel = `+${numberProfilesLeft.toLocaleString(
    "en-US"
  )} more Documenter${numberProfilesLeft === 1 ? "" : "s"}`

  return html`
    ${repeat(
      documentersList,
      (documenter) => documenter.id,
      (documenter) => html`
        <li role="none" class="search-result">
          <span class="icon">
            <i class=${TYPE_ICON_MAP["documenter"]}></i>
          </span>
          ${resultOption({
            url: documenter.url,
            label: `${documenter.name} ${
              documenter.programs !== "" ? `(${documenter.programs})` : ""
            }`,
            onBlur,
          })}
        </li>
      `
    )}
    ${numberProfilesLeft
      ? html`
          <li role="none" class="search-result">
            ${isAuthenticated
              ? resultOption({
                  url: `${DOCUMENTERS_HREF}?${queryParams}`,
                  label: numberProfilesLeftLabel,
                  onBlur,
                })
              : html`<span>${numberProfilesLeftLabel}</span>`}
          </li>
        `
      : ``}
  `
}

const SearchResults = ({
  isAuthenticated,
  loading,
  value,
  results,
  onBlur,
}) => {
  if (loading) {
    return html` <li role="none" class="search-result">Loading results...</li> `
  }
  if (!value || !value.trim()) {
    return html`
      <li role="none" class="search-result">
        By Documenter profiles, agencies, meetings, and documents
      </li>
    `
  }

  const resultsCountList = results.filter(
    (result) => typeof result[1] === "number" && result[1] > 0
  )

  const resultsDocumentersList = results.find(
    (result) => result[0] === "documenters"
  )
  const {
    data: documentersList,
    number_profiles_left: numberProfilesLeft,
    programs,
  } = resultsDocumentersList
    ? resultsDocumentersList[1]
    : { data: [], number_profiles_left: 0, programs: "" }

  return html`
    <li role="none" class="search-result help-result">
      "${value}" appears
      in${resultsCountList.length === 0 && documentersList.length === 0
        ? ` no content`
        : ``}
    </li>
    ${SearchDocumentersResults({
      isAuthenticated,
      documentersList,
      numberProfilesLeft,
      programs,
      search: value,
      onBlur,
    })}
    ${SearchCountResults({
      resultsCountList,
      value,
      onBlur,
    })}
  `
}

const SiteSearch = virtual(({ endpoint, csrftoken, placeholder }) => {
  const fieldRef = useRef(null)
  const [hasRunFirstFetch, setHasRunFirstFetch] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState("")
  const [show, setShow] = useState(false)

  const updateResults = (value) => {
    if (!value || !value.trim()) {
      setResults([])
      setLoading(false)

      if (hasRunFirstFetch) {
        // Run the first fetch to check if the current user is authenticated
        return
      }
      setHasRunFirstFetch(true)
    }

    if (window.gtag) {
      window.gtag("event", "search", {
        event_category: "site",
        event_label: value.toLowerCase(),
        anonymize_ip: true,
      })
    }
    fetch(`${endpoint}${value}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setIsAuthenticated(res.is_authenticated)
        setResults(Object.entries(res.search_result))
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        addToastMessage("There was an error completing your search", "error")
      })
  }

  useEffect(() => {
    setLoading(true)
    const handler = setTimeout(() => updateResults(value), 500)
    return () => clearTimeout(handler)
  }, [value])

  const onOpenInput = () => {
    if (!show) setShow(true)
  }

  const onCloseInput = () => {
    if (show) setShow(false)
  }

  // Delay blur event to avoid closing the dropdown too soon on Safari
  const onBlur = (e) => {
    const clickedWithinDropdown =
      fieldRef &&
      !!e.relatedTarget &&
      fieldRef.current.contains(e.relatedTarget)
    if (!clickedWithinDropdown) {
      setOpen(false)
    }
  }

  const onKeyDown = (e) => {
    if (["ArrowDown", "ArrowRight"].includes(e.code)) {
      const $siteSearchOptions = document.getElementById(
        "site-search-results-options"
      )
      $siteSearchOptions?.querySelector(`li > a[role="option"]`)?.focus()
    }
  }

  return html`
    <div
      data-action="site-search"
      class="typeahead ${show
        ? `absolute md:relative`
        : `relative`} left-0 right-auto h-full bg-white"
      ?ref=${ref(fieldRef)}
    >
      <div class="w-full h-full flex justify-between items-center">
        <input
          class="input search ${show ? `` : `hidden`} md:inline-block"
          type="text"
          .value=${value}
          @focus=${() => setOpen(true)}
          @blur=${onBlur}
          @input=${(e) => setValue(e.target.value)}
          @change=${(e) => setValue(e.target.value)}
          @keydown=${onKeyDown}
          placeholder=${placeholder}
          aria-label=${placeholder}
          role="combobox"
          autocomplete="off"
          aria-describedby="site-search-instructions"
          aria-owns="site-search-results-options"
          aria-autocomplete="list"
          aria-expanded=${open.toString()}
        />
        ${show
          ? html`<button
              type="button"
              class="ml-6 inline-block md:hidden text-sm"
              @click=${onCloseInput}
            >
              Close
            </button>`
          : nothing}
      </div>
      ${show
        ? nothing
        : html`<button
            type="button"
            class="inline-block md:hidden"
            @click="${onOpenInput}}"
          >
            <i class="cb fa-search"></i>
          </button>`}
      <i class="cb fa-search hidden md:inline-block"></i>
      <ul
        id="site-search-results-options"
        style="max-height: 26rem; overflow-y: scroll"
        class="search-results ${open ? `` : `hidden`}"
        role="listbox"
      >
        ${SearchResults({
          isAuthenticated,
          loading,
          value,
          results,
          onBlur,
        })}
      </ul>
      <span id="site-search-instructions" class="visually-hidden">
        When autocomplete results are available, use arrow keys to move through
        option links and return to follow the links to a detailed list of
        results.
      </span>
    </div>
  `
})

const setupSiteSearch = (el) => {
  const input = el.querySelector("input")
  render(
    SiteSearch({
      placeholder: input.placeholder,
      csrftoken: Cookies.get("csrftoken"),
      endpoint: input.dataset.endpoint,
    }),
    el.parentNode
  )
}

export { SiteSearch, setupSiteSearch }
