import FileInput from "../inputs/file"
import ModalForm from "./modal"

export default function DocumentModalForm(el) {
  const modalMount = document.getElementById("modal-mount")

  ModalForm(el, null, () => {
    const modalForm = modalMount.querySelector("form")
    const csrftoken = document
      .querySelector('input[name="csrfmiddlewaretoken"]')
      .cloneNode(true)
    modalForm.appendChild(csrftoken)
    modalForm
      .querySelectorAll(`[data-component="file-input"]`)
      .forEach((el) => FileInput(el.parentNode))
  })
}
