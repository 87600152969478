import { html, render } from "lit-html"
import { virtual, useRef, useState, useEffect } from "haunted"

import HXModal from "../generic/hx-modal"
import { ref } from "../directives"

const MODAL_TITLE_ID = "assignment-app-form-title"

const AssignmentAppButtonForm = virtual(({ className = ``, ...props }) => {
  const buttonRef = useRef(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const assignmentDetailPattern = /assignments\/[\d\w-]+\//
    if (assignmentDetailPattern.test(window.location.pathname)) {
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.has("apply")) {
        buttonRef.current.click()
      }
    }
  }, [])

  const onClose = () => {
    setOpen(false)

    if (buttonRef.current) {
      buttonRef.current.focus()
    }
  }

  return html`<button
      type="button"
      class="button min-w-240 link-text-color ${className}"
      ?ref=${ref(buttonRef)}
      @click=${() => setOpen(!open)}
    >
      ${props.title}
    </button>
    ${open
      ? HXModal({
          name: "assignment-app",
          action: props.action,
          labelledBy: MODAL_TITLE_ID,
          onClose,
        })
      : ``}`
})

export default AssignmentAppButtonForm

export function renderAssignmentAppForm(el) {
  const {
    app_form: { title, action },
  } = JSON.parse(
    document.querySelector(`[type="application/json"][data-props]`).innerText
  )

  render(
    AssignmentAppButtonForm({
      title,
      action,
    }),
    el
  )
}
