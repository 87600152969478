import { focusOnFirstChild, onFocusModalTrap } from "../utils"

export default function ModalForm(
  buttonEl,
  template = null,
  callback = null,
  onSubmit = null
) {
  const modalMount = document.getElementById("modal-mount")
  const focusBound = document.createElement("div")
  focusBound.dataset.focusTrap = ""
  focusBound.tabIndex = "0"

  const templateEl =
    template ||
    document.querySelector(`template[data-form="${buttonEl.dataset.form}"]`)

  const onClose = () => {
    buttonEl.focus()
    modalMount.innerHTML = ``
  }

  const onClickButton = () => {
    const modalContent = templateEl.content.cloneNode(true)
    modalMount.appendChild(focusBound.cloneNode(true))
    modalMount.appendChild(modalContent)
    modalMount.appendChild(focusBound.cloneNode(true))

    focusOnFirstChild(modalMount)

    const onKeydown = (event) => {
      if (event.key === "Escape") {
        onClose()
        removeListeners()
      }
    }
    const onDocClick = (event) => {
      if ("modal" in event.target.dataset) {
        onClose()
        removeListeners()
      }
    }

    const onFocus = () => onFocusModalTrap(modalMount)

    document.addEventListener("keydown", onKeydown, false)
    document.addEventListener("click", onDocClick, false)
    document.addEventListener("focus", onFocus, true)

    // Fire optional callback for processing content after mount
    if (callback) {
      callback()
    }

    const removeListeners = () => {
      document.removeEventListener("keydown", onKeydown)
      document.removeEventListener("click", onDocClick)
      document.removeEventListener("focus", onFocus)
    }

    modalMount
      .querySelector("button[data-action='close']")
      .addEventListener("click", () => {
        onClose()
        removeListeners()
      })
    if (onSubmit) {
      modalMount
        .querySelector("button[data-action='submit']")
        .addEventListener("click", () => {
          onSubmit()
          onClose()
          removeListeners()
        })
    }
  }

  buttonEl.addEventListener("click", onClickButton)
}
